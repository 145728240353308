import { ChevronRightRounded } from '@mui/icons-material'
import { Mixpanel } from '../../Mixpanel'
import BackButton from '../../commons/BackButton'
import { Divider } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faBoltLightning } from '@fortawesome/free-solid-svg-icons'

export default ({ handleBack, handleSubmit, setAutoBooking }) => {
  return (
    <>
      <BackButton handleBack={handleBack} />
      <div className='flex flex-col px-6 h-[93vh] narrow:h-[92vh] justify-start sm:items-center'>
        <div className='sm:w-1/2 grow overflow-auto py-6'>
          <div className='flex items-center justify-center'>
            <img src={require('../../assets/img/auto-booking.png')} className='w-2/3' />
          </div>
          <p className='text-start mb-8 text-xl narrow:text-2xl'>
            Activá la Reserva Automática para tus pasajeros
          </p>
          <div className='flex items-center mb-8 gap-3'>
            <FontAwesomeIcon icon={faBell} className='text-light_coral text-lg' />
            <div className='text-start text-sm'>
              <p className='mb-1'>Más práctico</p>
              <p className='text-gray'>
                No necesitás revisar el solicitud de cada pasajero ante que expire.
              </p>
            </div>
          </div>
          <div className='flex items-center mb-12 gap-3'>
            <FontAwesomeIcon icon={faBoltLightning} className='text-light_coral text-lg' />
            <div className='text-start text-sm'>
              <p className='mb-1'>Más pasajeros</p>
              <p className='text-gray'>Prefieren tener una respuesta rapida.</p>
            </div>
          </div>
          <div className='flex flex-col justify-center'>
            <div className='flex flex-col gap-8'>
              <div
                onClick={() => {
                  setAutoBooking(true)
                  Mixpanel.track('Reserva Automatica - Si')
                  handleSubmit()
                }}
                className='flex flex-row w-full justify-between items-center cursor-pointer'
              >
                <p className='text-light_coral text-start'>Activar Reserva Automática</p>
                <ChevronRightRounded className='text-gray' />
              </div>
              <Divider />
              <div
                onClick={() => {
                  setAutoBooking(false)
                  Mixpanel.track('Reserva Automatica - No')
                  handleSubmit()
                }}
                className='flex flex-row w-full justify-between items-center cursor-pointer'
              >
                <p className='text-start text-sm text-gray'>
                  Revisar cada solicitud antes de que expire
                </p>
                <ChevronRightRounded className='text-gray' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
